import { UserData, userDataAtom } from '../../atom/preInvite.atom';
import { useAtomValue } from 'jotai';
import { ArrowLeftIcon, BuildingIcon } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';

export function GoBack() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const userData = useAtomValue<UserData>(userDataAtom);

  if (!userData.selectedCompany.id || pathname === '/sucesso') return null;

  return (
    <button onClick={() => navigate('/')} className="text-typography-primary flex items-center gap-4 text-sm font-medium">
      <ArrowLeftIcon size={20} className="text-primary" />
      <div className="flex h-8 items-center justify-center gap-2 rounded bg-gray-200 px-2">
        <BuildingIcon size={20} className="text-secondary-green" />
        {userData.selectedCompany.name}
      </div>
    </button>
  );
}
