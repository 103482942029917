import { motion } from 'framer-motion';
import { routeAnimationVariants } from '@pre-invite/App';
import BVStyleguide from '@bv/styleguide';
import * as singleSpa from 'single-spa';
import { useAtomValue } from 'jotai';
import { UserData, userDataAtom } from '../../atom/preInvite.atom';

export function Success() {
  const userData = useAtomValue<UserData>(userDataAtom);

  return (
    <motion.div
      animate="final"
      initial="initial"
      exit="exit"
      variants={routeAnimationVariants}
      transition={{
        type: 'spring',
        stiffness: 400,
        damping: 20,
      }}>
      <div className="flex flex-col gap-8 sm:max-w-[70%]">
        <BVStyleguide.Alert variant="info">
          <BVStyleguide.AlertTitle>Parabéns!</BVStyleguide.AlertTitle>
          <BVStyleguide.AlertDescription>
            Agora você pode iniciar seu cadastro no <strong>Benefício Visão</strong>.
          </BVStyleguide.AlertDescription>
        </BVStyleguide.Alert>
        <span className="text-typography-secondary text-sm">
          Vamos usar essa informação para confirmar que você foi adicionado ao nosso benefício.
        </span>
        <BVStyleguide.Button className="w-full" onClick={() => singleSpa.navigateToUrl(userData.inviteUrl)}>
          Fazer cadastro
        </BVStyleguide.Button>
      </div>
    </motion.div>
  );
}
