import { api } from './api';

export type CreateOtpDTO = {
  email: string;
  companyId: string;
};

export type PostValidateOtpDTO = {
  email: string;
  code: string;
};

export type PostValidateRegistrationNumberDTO = {
  companyId: string;
  registrationNumber: string;
};

export type PostValidateDocumentDTO = {
  companyId: string;
  registrationNumber: string;
  document: string;
};

export type GetValidateRegistrationNumberParams = {
  registrationNumber: string;
  companyId: string;
};

export type GetValidateDocumentParams = {
  document: string;
  companyId: string;
};

async function postSendOtpService(body: CreateOtpDTO): Promise<{ message: string }> {
  return api.post('/v2/invites/otp/send', body);
}

async function postValidateOtpService(body: PostValidateOtpDTO): Promise<{ inviteUrl: string }> {
  return api.post('/v2/invites/otp/validate', body);
}

async function postValidateRegistrationNumberService(body: GetValidateRegistrationNumberParams): Promise<{ validate: boolean }> {
  return api.post('/v2/invites/validate/registration-number', body);
}

async function postValidateDocumentService(body: PostValidateDocumentDTO): Promise<{ inviteUrl: string }> {
  return api.post('/v2/invites/validate/document', body);
}

export { postSendOtpService, postValidateOtpService, postValidateRegistrationNumberService, postValidateDocumentService };
