import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { Layout } from './components/Layout';
import IdentifierPage from './pages/Identifier';
import OTPValidationPage from './pages/OTPValidation';
import SearchCompanyPage from './pages/SearchCompany';
import { Success } from './pages/Success';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AnimatePresence } from 'framer-motion';

const router = createBrowserRouter(
  [
    {
      element: (
        <Layout>
          <AnimatePresence>
            <Outlet />
          </AnimatePresence>
        </Layout>
      ),
      children: [
        {
          path: '/',
          element: <SearchCompanyPage />,
        },
        {
          path: '/identificador',
          element: <IdentifierPage />,
        },
        {
          path: '/validar-otp',
          element: <OTPValidationPage />,
        },
        {
          path: '/sucesso',
          element: <Success />,
        },
      ],
    },
  ],
  { basename: '/pre-convite' },
);

export const routeAnimationVariants = {
  initial: {
    opacity: 0,
    y: -24,
  },
  final: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: 16,
  },
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}
