import { atom } from 'jotai';
import { InviteValidationType } from '../services/company.service';

export type UserData = {
  selectedCompany: {
    name: string;
    id: string;
    inviteValidationType: InviteValidationType;
    document: string;
    corporateName: string;
  };
  email: string;
  otp: string;
  document: string;
  registrationNumber: string;
  inviteUrl: string;
};

export const userDataAtom = atom({
  selectedCompany: {
    name: '',
    corporateName: '',
    id: '',
    inviteValidationType: InviteValidationType.EMAIL,
    document: '',
  },
  email: '',
  otp: '',
  document: '',
  registrationNumber: '',
  inviteUrl: '',
});
