import { useState } from 'react';
import { RegistrationNumber } from './components/RegistrationNumberForm';
import { DocumentForm } from './components/DocumentForm';

export function EnrollmentFlow() {
  const [currentStep, setCurrentStep] = useState<'enrollment' | 'document'>('enrollment');
  return (
    <div>
      {currentStep === 'enrollment' && <RegistrationNumber setCurrentStep={setCurrentStep} />}
      {currentStep === 'document' && <DocumentForm />}
    </div>
  );
}
