import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import App from './App';
import BVStyleguide from '@bv/styleguide';

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: () => (
    <StrictMode>
      <App />
    </StrictMode>
  ),
  renderType: 'createRoot',
  domElementGetter: () => document.getElementById('main'),
  errorBoundary(err, info, props) {
    return <BVStyleguide.ErrorPleaseReload err={err} info={info} props={props} />;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
