import { routeAnimationVariants } from '@pre-invite/App';
import { motion } from 'framer-motion';
import { useAtom } from 'jotai';
import { UserData, userDataAtom } from '../../atom/preInvite.atom';
import { InviteValidationType } from '../../services/company.service';
import { EmailForm } from './components/EmailForm';
import { EnrollmentFlow } from './components/EnrollmentFlow';

export default function IdentifierPage() {
  const [userData, setUserData] = useAtom<UserData>(userDataAtom);

  return (
    <motion.div
      transition={{
        type: 'spring',
        stiffness: 400,
        damping: 20,
      }}
      key="identifier"
      exit="exit"
      animate="final"
      initial="initial"
      variants={routeAnimationVariants}>
      {userData.selectedCompany.inviteValidationType === InviteValidationType.EMAIL && <EmailForm />}
      {userData.selectedCompany.inviteValidationType === InviteValidationType.MATRICULA && <EnrollmentFlow />}
    </motion.div>
  );
}
