import { motion, useAnimate, usePresence } from 'framer-motion';
import { useEffect } from 'react';

export function ColabAnimated({ colab }: { colab: string }) {
  const [scope, animate] = useAnimate();
  const [isPresent, safeToRemove] = usePresence();

  useEffect(() => {
    async function animateColab() {
      await animate(scope.current, { y: 20, x: -40, opacity: 0 }, { duration: 0, ease: 'linear' });

      await animate(scope.current, { y: 0, x: -80, opacity: 1 }, { duration: 3, ease: 'linear' });
    }

    async function removeColab() {
      await animate(
        scope.current,
        {
          opacity: 0,
        },
        { duration: 0.3 },
      );
      safeToRemove();
    }

    if (isPresent) animateColab();
    else removeColab();
  }, [isPresent]);

  return (
    <img className="absolute bottom-[0px] left-[76px] max-[1366px]:-bottom-24" style={{ opacity: 0 }} ref={scope} src={colab} alt="Colaborador" />
  );
}
