import BVStyleguide from '@bv/styleguide';
import BVUtils from '@bv/utils';

export function EnrollmentDetailsModal() {
  if (BVUtils.device.IS_MOBILE) {
    return (
      <BVStyleguide.Drawer>
        <BVStyleguide.DrawerTrigger>
          <BVStyleguide.Button className="mb-4 px-0" type="button" variant="link">
            O que é o número de matrícula?
          </BVStyleguide.Button>
        </BVStyleguide.DrawerTrigger>
        <BVStyleguide.DrawerContent>
          <BVStyleguide.DrawerHeader>
            <BVStyleguide.DrawerTitle>Número de matrícula</BVStyleguide.DrawerTitle>
            <BVStyleguide.DrawerDescription>O que o número de matrícula?</BVStyleguide.DrawerDescription>
          </BVStyleguide.DrawerHeader>
          <div className="space-y-4 p-4 pt-0">
            <p className="text-typography-secondary">
              É o código que sua empresa usa para identificar você como colaborador. Pode incluir números, letras ou ambos.
            </p>
            <p className="text-typography-secondary">
              Se não souber o seu número de matrícula, entre em contato com a equipe do Benefício Visão (suporte@beneficiovisao.com.br) ou da sua
              empresa.
            </p>
          </div>
          <BVStyleguide.DrawerFooter>
            <BVStyleguide.DrawerClose>
              <BVStyleguide.Button variant="ghost" type="button" className="w-full">
                Fechar
              </BVStyleguide.Button>
            </BVStyleguide.DrawerClose>
          </BVStyleguide.DrawerFooter>
        </BVStyleguide.DrawerContent>
      </BVStyleguide.Drawer>
    );
  }
  return (
    <BVStyleguide.Dialog>
      <BVStyleguide.DialogTrigger>
        <BVStyleguide.Button className="mb-4 px-0" type="button" variant="link">
          O que é o número de matrícula?
        </BVStyleguide.Button>
      </BVStyleguide.DialogTrigger>
      <BVStyleguide.DialogContent>
        <BVStyleguide.DialogHeader>
          <BVStyleguide.DialogTitle>Número de matrícula</BVStyleguide.DialogTitle>
          <BVStyleguide.DialogDescription>O que o número de matrícula?</BVStyleguide.DialogDescription>
        </BVStyleguide.DialogHeader>
        <div className="space-y-4 py-4">
          <p className="text-typography-secondary">
            É o código que sua empresa usa para identificar você como colaborador. Pode incluir números, letras ou ambos.
          </p>
          <p className="text-typography-secondary">
            Se não souber o seu número de matrícula, entre em contato com a equipe do Benefício Visão (suporte@beneficiovisao.com.br) ou da sua
            empresa.
          </p>
        </div>
        <BVStyleguide.DialogFooter>
          <BVStyleguide.DialogClose>
            <BVStyleguide.Button variant="ghost" type="button">
              Fechar
            </BVStyleguide.Button>
          </BVStyleguide.DialogClose>
        </BVStyleguide.DialogFooter>
      </BVStyleguide.DialogContent>
    </BVStyleguide.Dialog>
  );
}
