import BVStyleguide from '@bv/styleguide';
import { routeAnimationVariants } from '@pre-invite/App';
import { UserData, userDataAtom } from '../../atom/preInvite.atom';
import { GET_SEARCH_COMPANY, GET_VALIDATE_COMPANY } from '@pre-invite/consts/queries';
import { useQuery } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { useAtom } from 'jotai';
import { debounce } from 'lodash';
import { BuildingIcon, InfoIcon } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { getSearchCompanyService, GetValidateCompanyResponse, getValidateCompanyService, InviteValidationType } from '../../services/company.service';
import { CompanyDetailsModal } from './components/CompanyDetailsModal';

const mock = [
  {
    value: '9ad5c4f9-18b0-4597-a5f4-9c173c66f1c9',
    label: 'lumos language school',
    inviteValidationType: 'EMAIL',
  },
  {
    value: 'd718bf0e-8905-4291-bb19-727518a59db5',
    label: 'lumos language school',
    inviteValidationType: 'EMAIL',
  },
  {
    value: '9da521ad-1451-4bf3-980b-a755f3e26136',
    label: 'lumos language school',
    inviteValidationType: 'EMAIL',
  },
  {
    value: '9bf594b2-e776-4d22-b3f0-2eb9bb7e3f31',
    label: 'lumos language school ffff',
    inviteValidationType: 'EMAIL',
  },
  {
    value: '13ab50bd-d67e-426d-9e7f-36bf95284955',
    label: 'lumos language school jo',
    inviteValidationType: 'EMAIL',
  },
  {
    value: '1635a83d-95bd-4995-bb76-72a20245d7b0',
    label: 'lumos language school',
    inviteValidationType: 'REGISTRATION_NUMBER',
  },
];

export default function SearchCompanyPage() {
  const [value, setValue] = useState('');
  const [modalCompanyDetails, setModalCompanyDetails] = useState(false);
  const [dropdownCompanies, setDropdownCompanies] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<GetValidateCompanyResponse>(null);
  const [userData, setUserData] = useAtom<UserData>(userDataAtom);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [companiesList, setCompaniesList] = useState([]);
  const navigate = useNavigate();

  const companyId = useMemo(() => searchParams.get('companyId'), [searchParams]);

  const { data: validateCompanyById, isFetching: isValidateCompanyByIdFetching } = useQuery({
    queryKey: [GET_VALIDATE_COMPANY, { companyId }],
    queryFn: getValidateCompany,
    enabled: !!companyId,
  });

  const { data: companies, isFetching: isCompaniesFetching } = useQuery({
    queryKey: [GET_SEARCH_COMPANY, searchTerm],
    queryFn: getCompanies,
    enabled: searchTerm?.length > 2,
  });

  async function getValidateCompany() {
    try {
      const response = await getValidateCompanyService(companyId);
      setUserData({
        ...userData,
        selectedCompany: {
          ...response,
        },
      });
      navigate({
        pathname: '/identificador',
        search: createSearchParams({ companyId }).toString(),
      });
      return response;
    } catch (error) {}
  }

  async function getCompanies() {
    try {
      const response = await getSearchCompanyService(searchTerm);
      setDropdownCompanies(true);
      if (response.length) {
        const responseToSelectOptions = response.map((company) => ({ value: company.id, label: company.name }));
        setCompaniesList(responseToSelectOptions);
        return response;
      }
      setCompaniesList([]);
      return [];
    } catch (error) {
      setCompaniesList([]);
      return [];
    }
  }

  const debouncedSetSearchTerm = useCallback(
    debounce((newValue) => {
      setSearchTerm(newValue);
      setSelectedCompany(null);
    }, 1000),
    [],
  );

  function handleSelectCompanyToProceed(companyId: string) {
    const selectedCompanyToProceed = companies.find((company) => company.id === companyId);
    setUserData({
      ...userData,
      selectedCompany: {
        ...selectedCompanyToProceed,
      },
    });
    navigate('/identificador');
  }

  function handleCompanyDetails(companyId: string) {
    const selectedCompanyDetails = companies.find((company) => company.id === companyId);
    setSelectedCompany(selectedCompanyDetails);
    setModalCompanyDetails(true);
  }

  useEffect(() => {
    if (!modalCompanyDetails && companies?.length) {
      setDropdownCompanies(true);
    }
  }, [modalCompanyDetails]);

  useEffect(() => {
    if (!companyId)
      setUserData({
        ...userData,
        selectedCompany: {
          name: '',
          id: '',
          corporateName: '',
          document: '',
          inviteValidationType: InviteValidationType.EMAIL,
        },
      });
  }, []);

  return (
    <motion.div
      transition={{
        type: 'spring',
        stiffness: 400,
        damping: 20,
      }}
      animate="final"
      key={'searchCompany'}
      initial="initial"
      variants={routeAnimationVariants}>
      <h2 className="text-typography-primary">
        Busque a empresa que oferece o <span className="text-primary whitespace-nowrap">Benefício Visão</span> para você!
      </h2>
      <span className="text-typography-secondary text-xs">Um benefício corporativo.</span>

      {isValidateCompanyByIdFetching ? (
        <div className="mt-4 flex items-center gap-3">
          <BVStyleguide.Loader text="Já identificamos sua empresa, aguarde..." />
        </div>
      ) : (
        <div className="relative mt-4">
          <div className="relative sm:w-[300px]">
            <BVStyleguide.InputPure
              type="text"
              placeholder="Encontre sua empresa..."
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                debouncedSetSearchTerm(e.target.value);
              }}
            />
            {isCompaniesFetching && (
              <div className="absolute right-2 top-[10px]">
                <BVStyleguide.Loader />
              </div>
            )}
          </div>
          <BVStyleguide.DropdownMenu onOpenChange={setDropdownCompanies} open={dropdownCompanies}>
            <BVStyleguide.DropdownMenuTrigger className="absolute w-full sm:w-[300px]">
              <div className="w-full" />
            </BVStyleguide.DropdownMenuTrigger>
            <BVStyleguide.DropdownMenuContent className="max-h-[200px] w-full overflow-y-auto">
              {!companiesList?.length ? (
                <BVStyleguide.DropdownMenuLabel>
                  <span className="text-typography-secondary/80 py-3 font-semibold">Nenhuma empresa encontrada.</span>
                </BVStyleguide.DropdownMenuLabel>
              ) : (
                companiesList?.map((company) => (
                  <BVStyleguide.DropdownMenuItem key={company.value} className={BVStyleguide.cn(value === company.value && 'bg-primary-100')}>
                    <button className="flex w-full items-center gap-2 py-2" onClick={() => handleSelectCompanyToProceed(company.value)}>
                      <BuildingIcon className="text-primary" size={20} />
                      {company.label}
                    </button>
                    <BVStyleguide.IconButton variant="ghost" size="sm" className="ml-auto" onClick={() => handleCompanyDetails(company.value)}>
                      <InfoIcon size={16} className="text-primary" />
                    </BVStyleguide.IconButton>
                  </BVStyleguide.DropdownMenuItem>
                ))
              )}
            </BVStyleguide.DropdownMenuContent>
          </BVStyleguide.DropdownMenu>
          <CompanyDetailsModal modal={modalCompanyDetails} setModal={setModalCompanyDetails} selectedCompany={selectedCompany} />
        </div>
      )}
    </motion.div>
  );
}
