import BVStyleguide from '@bv/styleguide';
import { UserData, userDataAtom } from '../../../../atom/preInvite.atom';
import { GetValidateCompanyResponse } from '../../../../services/company.service';
import { useAtom } from 'jotai';
import { BuildingIcon } from 'lucide-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import BVUtils from '@bv/utils';

type CompanyDetailsModalProps = {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCompany: GetValidateCompanyResponse;
};

export function CompanyDetailsModal({ modal, setModal, selectedCompany }: CompanyDetailsModalProps) {
  const [userData, setUserData] = useAtom<UserData>(userDataAtom);
  const navigate = useNavigate();

  if (BVUtils.device.IS_MOBILE) {
    return (
      <BVStyleguide.Drawer open={modal} onOpenChange={setModal}>
        <BVStyleguide.DrawerContent>
          <BVStyleguide.DrawerHeader>
            <BVStyleguide.DrawerTitle>
              <div className="flex h-8 w-8 items-center justify-center rounded bg-gray-200">
                <BuildingIcon size={20} className="text-secondary-green" />
              </div>
            </BVStyleguide.DrawerTitle>
            <BVStyleguide.DrawerDescription className="text-left">Veja os detalhes da empresa selecionada.</BVStyleguide.DrawerDescription>
          </BVStyleguide.DrawerHeader>
          <div className="space-y-4 p-4">
            <div className="flex flex-col gap-1">
              <span className="text-typography-secondary text-sm">Nome Comercial</span>
              <strong className="text-typography-primary">{selectedCompany?.name}</strong>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-typography-secondary text-sm">Razão Social</span>
              <strong className="text-typography-primary">{selectedCompany?.corporateName}</strong>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-typography-secondary text-sm">CNPJ</span>
              <strong className="text-typography-primary">{selectedCompany?.document}</strong>
            </div>
          </div>
          <BVStyleguide.DrawerFooter>
            <BVStyleguide.Button
              onClick={() => {
                setUserData({ ...userData, selectedCompany });
                navigate('/identificador');
              }}>
              Selecionar
            </BVStyleguide.Button>
            <BVStyleguide.Button variant="ghost" onClick={() => setModal(false)}>
              Fechar
            </BVStyleguide.Button>
          </BVStyleguide.DrawerFooter>
        </BVStyleguide.DrawerContent>
      </BVStyleguide.Drawer>
    );
  }

  return (
    <BVStyleguide.Dialog open={modal} onOpenChange={setModal}>
      <BVStyleguide.DialogContent>
        <BVStyleguide.DialogHeader>
          <BVStyleguide.DialogTitle>
            <div className="flex h-8 w-8 items-center justify-center rounded bg-gray-200">
              <BuildingIcon size={20} className="text-secondary-green" />
            </div>
          </BVStyleguide.DialogTitle>
          <BVStyleguide.DialogDescription>Veja os detalhes da empresa selecionada.</BVStyleguide.DialogDescription>
        </BVStyleguide.DialogHeader>
        <div className="space-y-4 py-4">
          <div className="flex flex-col gap-1">
            <span className="text-typography-secondary text-sm">Nome Comercial</span>
            <strong className="text-typography-primary">{selectedCompany?.name}</strong>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-typography-secondary text-sm">Razão Social</span>
            <strong className="text-typography-primary">{selectedCompany?.corporateName}</strong>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-typography-secondary text-sm">CNPJ</span>
            <strong className="text-typography-primary">{selectedCompany?.document}</strong>
          </div>
        </div>
        <BVStyleguide.DialogFooter>
          <BVStyleguide.Button variant="ghost" onClick={() => setModal(false)}>
            Fechar
          </BVStyleguide.Button>
          <BVStyleguide.Button
            onClick={() => {
              setUserData({ ...userData, selectedCompany });
              navigate('/identificador');
            }}>
            Selecionar
          </BVStyleguide.Button>
        </BVStyleguide.DialogFooter>
      </BVStyleguide.DialogContent>
    </BVStyleguide.Dialog>
  );
}
